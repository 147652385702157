import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useSearch from "../../hooks/useSearch";

interface IData {
  headlist: string[];
  bodylist: any[];
}

export default function UserTable() {
  const { data, error, loading, request } = useSearch();
  const [tableDataRows, setTableDataRows] = useState<any[]>([]);
  const tableData: IData = {
    headlist: ["Device Id", "Supply Number", "Address", "isActive"],
    bodylist: tableDataRows,
  };

  useEffect(() => {
    request("87654321");
  }, []);
  useEffect(() => {
    if (Object.entries(data).length > 0) {
      data.forEach((element: any) => {
        element.devices.forEach((device:any)=>{
          setTableDataRows([...tableDataRows,{"ext_device_id":device.ext_device_id,"SupplyNumber":element.supply.supply_nr,"Address":device.description,"isActive":true}]);
        })
      });
    }
  }, [data]);
  return (
    <Box
      p={1}
      borderRadius="6px"
      shadow="md"
      borderWidth="1px"
      backgroundColor="white"
      className="estimation-box"
    >
      <UserTableItem
        headlist={tableData.headlist}
        bodylist={tableData.bodylist}
      />
    </Box>
  );
}

function UserTableItem({ headlist, bodylist }: IData) {
  return (
    <TableContainer>
      <Table
        variant="simple"
        bg={useColorModeValue("white", "gray.900")}
        borderColor={useColorModeValue("gray.200", "gray.700")}
        colorScheme="linkedin"
      >
        <Thead background="gray.100">
          <Tr>
            <UserTableHeadItem content={headlist} />
          </Tr>
        </Thead>
        <Tbody>
          {bodylist.map(device => (
            <Tr key={device.ext_device_id}>
              <UserTableBodyItem content={device} />
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function UserTableHeadItem({ content }: any) {
  return (
    <>
      {content.map((c:string) => {
        return (
          <Th color="teal" key={c} fontSize="md">
            {c}
          </Th>
        );
      })}
    </>
  );
}

const UserTableBodyItem = ({ content }: any) => {
  return (
    <>
      {Object.keys(content).map((c) => {
        return <Td key={c}>{c!=="isActive"?content[c]:content[c]?'active':'inactive'}</Td>;
      })}
    </>
  );
};
