// src/use-descendant.ts
import { createContext } from "@chakra-ui/react-context";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { useRef, useState } from "react";

// src/utils.ts
import { useEffect, useLayoutEffect } from "react";
function sortNodes(nodes) {
  return nodes.sort((a, b) => {
    const compare = a.compareDocumentPosition(b);
    if (compare & Node.DOCUMENT_POSITION_FOLLOWING || compare & Node.DOCUMENT_POSITION_CONTAINED_BY) {
      return -1;
    }
    if (compare & Node.DOCUMENT_POSITION_PRECEDING || compare & Node.DOCUMENT_POSITION_CONTAINS) {
      return 1;
    }
    if (compare & Node.DOCUMENT_POSITION_DISCONNECTED || compare & Node.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC) {
      throw Error("Cannot sort the given nodes.");
    } else {
      return 0;
    }
  });
}
var isElement = (el) => typeof el == "object" && "nodeType" in el && el.nodeType === Node.ELEMENT_NODE;
function getNextIndex(current, max, loop) {
  let next = current + 1;
  if (loop && next >= max)
    next = 0;
  return next;
}
function getPrevIndex(current, max, loop) {
  let next = current - 1;
  if (loop && next < 0)
    next = max;
  return next;
}
var useSafeLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;
var cast = (value) => value;

// src/descendant.ts
var DescendantsManager = class {
  descendants = /* @__PURE__ */ new Map();
  register = (nodeOrOptions) => {
    if (nodeOrOptions == null)
      return;
    if (isElement(nodeOrOptions)) {
      return this.registerNode(nodeOrOptions);
    }
    return (node) => {
      this.registerNode(node, nodeOrOptions);
    };
  };
  unregister = (node) => {
    this.descendants.delete(node);
    const sorted = sortNodes(Array.from(this.descendants.keys()));
    this.assignIndex(sorted);
  };
  destroy = () => {
    this.descendants.clear();
  };
  assignIndex = (descendants) => {
    this.descendants.forEach((descendant) => {
      const index = descendants.indexOf(descendant.node);
      descendant.index = index;
      descendant.node.dataset["index"] = descendant.index.toString();
    });
  };
  count = () => this.descendants.size;
  enabledCount = () => this.enabledValues().length;
  values = () => {
    const values = Array.from(this.descendants.values());
    return values.sort((a, b) => a.index - b.index);
  };
  enabledValues = () => {
    return this.values().filter((descendant) => !descendant.disabled);
  };
  item = (index) => {
    if (this.count() === 0)
      return void 0;
    return this.values()[index];
  };
  enabledItem = (index) => {
    if (this.enabledCount() === 0)
      return void 0;
    return this.enabledValues()[index];
  };
  first = () => this.item(0);
  firstEnabled = () => this.enabledItem(0);
  last = () => this.item(this.descendants.size - 1);
  lastEnabled = () => {
    const lastIndex = this.enabledValues().length - 1;
    return this.enabledItem(lastIndex);
  };
  indexOf = (node) => {
    var _a;
    if (!node)
      return -1;
    return ((_a = this.descendants.get(node)) == null ? void 0 : _a.index) ?? -1;
  };
  enabledIndexOf = (node) => {
    if (node == null)
      return -1;
    return this.enabledValues().findIndex((i) => i.node.isSameNode(node));
  };
  next = (index, loop = true) => {
    const next = getNextIndex(index, this.count(), loop);
    return this.item(next);
  };
  nextEnabled = (index, loop = true) => {
    const item = this.item(index);
    if (!item)
      return;
    const enabledIndex = this.enabledIndexOf(item.node);
    const nextEnabledIndex = getNextIndex(enabledIndex, this.enabledCount(), loop);
    return this.enabledItem(nextEnabledIndex);
  };
  prev = (index, loop = true) => {
    const prev = getPrevIndex(index, this.count() - 1, loop);
    return this.item(prev);
  };
  prevEnabled = (index, loop = true) => {
    const item = this.item(index);
    if (!item)
      return;
    const enabledIndex = this.enabledIndexOf(item.node);
    const prevEnabledIndex = getPrevIndex(enabledIndex, this.enabledCount() - 1, loop);
    return this.enabledItem(prevEnabledIndex);
  };
  registerNode = (node, options) => {
    if (!node || this.descendants.has(node))
      return;
    const keys = Array.from(this.descendants.keys()).concat(node);
    const sorted = sortNodes(keys);
    if (options == null ? void 0 : options.disabled) {
      options.disabled = !!options.disabled;
    }
    const descendant = { node, index: -1, ...options };
    this.descendants.set(node, descendant);
    this.assignIndex(sorted);
  };
};

// src/use-descendant.ts
function useDescendants() {
  const descendants = useRef(new DescendantsManager());
  useSafeLayoutEffect(() => {
    return () => descendants.current.destroy();
  });
  return descendants.current;
}
var [DescendantsContextProvider, useDescendantsContext] = createContext({
  name: "DescendantsProvider",
  errorMessage: "useDescendantsContext must be used within DescendantsProvider"
});
function useDescendant(options) {
  const descendants = useDescendantsContext();
  const [index, setIndex] = useState(-1);
  const ref = useRef(null);
  useSafeLayoutEffect(() => {
    return () => {
      if (!ref.current)
        return;
      descendants.unregister(ref.current);
    };
  }, []);
  useSafeLayoutEffect(() => {
    if (!ref.current)
      return;
    const dataIndex = Number(ref.current.dataset["index"]);
    if (index != dataIndex && !Number.isNaN(dataIndex)) {
      setIndex(dataIndex);
    }
  });
  const refCallback = options ? cast(descendants.register(options)) : cast(descendants.register);
  return {
    descendants,
    index,
    enabledIndex: descendants.enabledIndexOf(ref.current),
    register: mergeRefs(refCallback, ref)
  };
}
function createDescendantContext() {
  const ContextProvider = cast(DescendantsContextProvider);
  const _useDescendantsContext = () => cast(useDescendantsContext());
  const _useDescendant = (options) => useDescendant(options);
  const _useDescendants = () => useDescendants();
  return [
    ContextProvider,
    _useDescendantsContext,
    _useDescendants,
    _useDescendant
  ];
}
export {
  createDescendantContext,
  createDescendantContext as default
};
