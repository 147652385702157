import AreaChart from "../../shared/charts/line-chart/line-chart.component";
import {
  Box,
  Divider,
  GridItem,
  SimpleGrid,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import EstimationCardsRow from "../../shared/cards/estimation-card/estimation-card-stack.component";
import SummaryCard from "../../shared/cards/summary-card.component.tsx/summary-card.component";
import DonutChartCard from "../../shared/cards/donut-chart-card/donut-chart-card.component";
import UserTable from "../../shared/tables/user-table/user-table.component";
import { useParams } from "react-router-dom";
import useGetHook from "../../shared/hooks/useGetHook";
import FilteringComponent from "../filtering-bar/filtering.component";
import { useEffect, useState } from "react";

export function Home() {
  const { id } = useParams();
  const { data, loading, error } = useGetHook({
    url: id ?? "/esp-30days-consumption",
    has_device_id: id ? true : false,
  });
  const [lineChartData, setLineChartData] = useState(data);
  const [cardChartData, setCardChartData] = useState(data);
  const [lineChartLoading, setLineChartLoading] = useState(false);
  const toast = useToast();
  const consumptionDataErrorToastId = "consumptionDataErrorToastId";

  useEffect(() => {
    //set the line chart data to the result of the useGetHook called on pageLoad
    setLineChartData(data);
    setCardChartData(data);
  }, [data]);

  const filterData = (filters: any) => {
    //set the line chart data to the output of the filtering component
    setLineChartData(filters.data);
    setCardChartData(filters.data);
    setLineChartLoading(filters.loading);
  };

  if (error && !toast.isActive(consumptionDataErrorToastId) && loading) {
    toast({
      id: "consumptionDataErrorToastId",
      title: "Could not load consumption data",
      position: "top-right",
      isClosable: true,
      status: "error",
    });
  }

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 3 }}
        rowGap={10}
        columnGap={{ base: 0, md: 0, lg: 10 }}
      >
        <GridItem colSpan={2}>
          {loading ? (
            <Spinner
              display="block"
              marginTop="auto"
              marginBottom="auto"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          ) : (
            <Stack>
              <FilteringComponent getFilteredDataCallback={filterData} />
              {lineChartLoading ? (
                <Spinner size="md" />
              ) : (
                <AreaChart data={lineChartData} id={id} />
              )}
            </Stack>
          )}
        </GridItem>
        <Box alignSelf="self-end">
          <Stack>
            <Box minWidth={288} mt="45px">
              <SummaryCard />
            </Box>
            <Divider />
            <Box minWidth={288}>
              <DonutChartCard />
            </Box>
          </Stack>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={1} spacing="40px" mt={4}>
        <Box maxW={"48rem"}>
          <EstimationCardsRow data={cardChartData} />
        </Box>
        <Box>
          <UserTable />
        </Box>
      </SimpleGrid>
    </>
  );
}
