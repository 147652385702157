import axios from "axios";
import { useContext } from "react";
import AuthContext from "../components/authentication/auth-context/AuthContext";

const UserDeviceDataApi = () => {
  const { auth }:any = useContext(AuthContext);

  const instance = axios.create({
    baseURL: "https://user-device-data.victoriouswater-0d62d7ab.francecentral.azurecontainerapps.io/api/v1/esp",
      headers: {
        "X-Api-Key": "2keFgQuDqagBnnYvg73SexFdMtxejYfS864J7TAFmUZf6",
        Authorization: `Bearer ${auth.accessToken??sessionStorage.getItem("access_token")}`,
      },
  });

  return instance;
};

export default UserDeviceDataApi;
