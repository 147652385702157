// ../../../react-shim.js
import React from "react";

// src/stat-arrow.tsx
import { Icon } from "@chakra-ui/icon";
import { chakra as chakra2 } from "@chakra-ui/system";

// src/stat.tsx
import { createContext } from "@chakra-ui/react-context";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// src/stat.tsx
var [StatStylesProvider, useStatStyles] = createContext({
  name: `StatStylesContext`,
  errorMessage: `useStatStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Stat />" `
});
var Stat = forwardRef(function Stat2(props, ref) {
  const styles = useMultiStyleConfig("Stat", props);
  const statStyles = {
    position: "relative",
    flex: "1 1 0%",
    ...styles.container
  };
  const { className, children, ...rest } = omitThemingProps(props);
  return /* @__PURE__ */ React.createElement(StatStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    ...rest,
    className: cx("chakra-stat", className),
    __css: statStyles
  }, /* @__PURE__ */ React.createElement("dl", null, children)));
});
Stat.displayName = "Stat";

// src/stat-arrow.tsx
var StatDownArrow = (props) => /* @__PURE__ */ React.createElement(Icon, {
  color: "red.400",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  fill: "currentColor",
  d: "M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z"
}));
StatDownArrow.displayName = "StatDownArrow";
function StatUpArrow(props) {
  return /* @__PURE__ */ React.createElement(Icon, {
    color: "green.400",
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    d: "M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z"
  }));
}
StatUpArrow.displayName = "StatUpArrow";
function StatArrow(props) {
  const { type, "aria-label": ariaLabel, ...rest } = props;
  const styles = useStatStyles();
  const BaseIcon = type === "increase" ? StatUpArrow : StatDownArrow;
  const defaultAriaLabel = type === "increase" ? "increased by" : "decreased by";
  const label = ariaLabel || defaultAriaLabel;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(chakra2.span, {
    srOnly: true
  }, label), /* @__PURE__ */ React.createElement(BaseIcon, {
    "aria-hidden": true,
    ...rest,
    __css: styles.icon
  }));
}
StatArrow.displayName = "StatArrow";

// src/stat-group.tsx
import { chakra as chakra3, forwardRef as forwardRef2 } from "@chakra-ui/system";
var StatGroup = forwardRef2(function StatGroup2(props, ref) {
  return /* @__PURE__ */ React.createElement(chakra3.div, {
    ...props,
    ref,
    role: "group",
    className: cx("chakra-stat__group", props.className),
    __css: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      alignItems: "flex-start"
    }
  });
});
StatGroup.displayName = "StatGroup";

// src/stat-help-text.tsx
import { chakra as chakra4, forwardRef as forwardRef3 } from "@chakra-ui/system";
var StatHelpText = forwardRef3(function StatHelpText2(props, ref) {
  const styles = useStatStyles();
  return /* @__PURE__ */ React.createElement(chakra4.dd, {
    ref,
    ...props,
    className: cx("chakra-stat__help-text", props.className),
    __css: styles.helpText
  });
});
StatHelpText.displayName = "StatHelpText";

// src/stat-label.tsx
import { chakra as chakra5, forwardRef as forwardRef4 } from "@chakra-ui/system";
var StatLabel = forwardRef4(function StatLabel2(props, ref) {
  const styles = useStatStyles();
  return /* @__PURE__ */ React.createElement(chakra5.dt, {
    ref,
    ...props,
    className: cx("chakra-stat__label", props.className),
    __css: styles.label
  });
});
StatLabel.displayName = "StatLabel";

// src/stat-number.tsx
import { chakra as chakra6, forwardRef as forwardRef5 } from "@chakra-ui/system";
var StatNumber = forwardRef5(function StatNumber2(props, ref) {
  const styles = useStatStyles();
  return /* @__PURE__ */ React.createElement(chakra6.dd, {
    ref,
    ...props,
    className: cx("chakra-stat__number", props.className),
    __css: {
      ...styles.number,
      fontFeatureSettings: "pnum",
      fontVariantNumeric: "proportional-nums"
    }
  });
});
StatNumber.displayName = "StatNumber";
export {
  Stat,
  StatArrow,
  StatDownArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  StatUpArrow,
  useStatStyles
};
