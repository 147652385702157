import {
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  useToast,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useFilterHook from "../../shared/hooks/useFilterHook";
import moment from "moment";

function FilteringComponent({ getFilteredDataCallback }: any) {
  let date = new Date();
  const [showCustomeDates, setShowCustomeDates] = useState(false);

  //TODO: Because I have dirty data, it is not sure that the predefined range will actually have data. Case the first load of ESP data comes from the useGetHook api
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  //useState(date.getFullYear() + "-" + ("0"+(date.getMonth()+1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
  const [endDate, setEndDate] = useState(
    date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
  );
  //useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
  const [granularity, setGranularity] = useState("daily");
  const { makeRequest, data, error, loading } = useFilterHook();
  const toast = useToast();

  useEffect(() => {
    if (!showCustomeDates) {
      //when in custom dates mode, the user calls the api when hitting the apply btn, so return from here
      console.log("granularity: " + granularity);
      console.log("start: " + startDate);
      console.log("end: " + endDate);
      makeRequest(granularity, startDate, endDate);
    }
  }, [granularity, startDate]);

  function showMessage(errMsg: string) {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: "error",
    });
  }

  function resetDatesBasedOnGranularity(interval: string) {
    switch (interval) {
      case "hourly":
        setGranularity("hourly");
        setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "daily":
        setGranularity("daily");
        setStartDate(moment().subtract(1, "month").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "monthly":
        setGranularity("monthly");
        setStartDate(moment().subtract(1, "year").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "yearly":
        setGranularity("yearly");
        setStartDate("");
        setEndDate("");
        break;
    }
  }

  function handleChangeDatesByArrow(direction: string) {
    if (direction === "left") {
      switch (granularity) {
        case "hourly":
          setStartDate(
            moment(startDate).subtract(1, "day").format("YYYY-MM-DD")
          );
          setEndDate(moment(endDate).subtract(1, "day").format("YYYY-MM-DD"));
          break;
        case "daily":
          setStartDate(
            moment(startDate).subtract(1, "month").format("YYYY-MM-DD")
          );
          setEndDate(moment(endDate).subtract(1, "month").format("YYYY-MM-DD"));
          break;
        case "monthly":
          setStartDate(
            moment(startDate).subtract(1, "year").format("YYYY-MM-DD")
          );
          setEndDate(moment(endDate).subtract(1, "year").format("YYYY-MM-DD"));
          break;
      }
    } else {
      switch (granularity) {
        case "hourly":
          setStartDate(moment(startDate).add(1, "day").format("YYYY-MM-DD"));
          setEndDate(moment(endDate).add(1, "day").format("YYYY-MM-DD"));
          break;
        case "daily":
          setStartDate(moment(startDate).add(1, "month").format("YYYY-MM-DD"));
          setEndDate(moment(endDate).add(1, "month").format("YYYY-MM-DD"));
          break;
        case "monthly":
          setStartDate(moment(startDate).add(1, "year").format("YYYY-MM-DD"));
          setEndDate(moment(endDate).add(1, "year").format("YYYY-MM-DD"));
          break;
      }
    }
  }

  useEffect(() => {
    getFilteredDataCallback({ data, loading });
  }, [data, loading]);

  useEffect(() => {
    if (error) {
      console.log(error);
      setGranularity("");
      showMessage("Oops, something went wrong!");
    }
  }, [error]);

  return (
    <>
      <Flex>
        <VStack>
          <Button
            alignSelf="flex-start"
            bg={"white"}
            onClick={() => {
              setShowCustomeDates(!showCustomeDates);
            }}
          >
            {!showCustomeDates ? "custom dates" : "simple dates"}
          </Button>
          <HStack
            spacing={"5"}
            flexWrap={{
              base: "nowrap",
              sm: "wrap",
              md: "wrap",
              lg: "wrap",
              xl: "nowrap",
            }}
          >
            {showCustomeDates ? (
              <Flex
                flexWrap={{
                  base: "nowrap",
                  sm: "wrap",
                  md: "wrap",
                  lg: "wrap",
                  xl: "nowrap",
                }}
              >
                <Button
                  bg={"gray.300"}
                  padding="5"
                  paddingLeft="10"
                  paddingRight="10"
                  marginLeft="auto"
                  marginRight="auto"
                  onClick={() => {
                    makeRequest(granularity, startDate, endDate);
                  }}
                >
                  apply
                </Button>
                <InputGroup>
                  <InputLeftAddon
                    bg={"gray.300"}
                    borderRadius="14px"
                    children="Starting Date:"
                  />
                  <Input
                    disabled={loading}
                    bg={"white"}
                    size="md"
                    borderRadius="14px"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      console.log(startDate);
                      setStartDate(e.target.value.toString());
                    }}
                  ></Input>
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon
                    bg={"gray.300"}
                    borderRadius="14px"
                    children="End Date:"
                  />
                  <Input
                    disabled={loading}
                    bg={"white"}
                    size="md"
                    borderRadius="14px"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value.toString())}
                  ></Input>
                </InputGroup>
              </Flex>
            ) : null}
            <ButtonGroup isDisabled={loading}>
              {!showCustomeDates ? (
                <>
                  <Button
                    disabled={granularity === "yearly"}
                    onClick={() => {
                      handleChangeDatesByArrow("left");
                    }}
                  >
                    <Text fontWeight="600">&lt;</Text>
                  </Button>
                  {granularity!='yearly'?<Text alignSelf="center" fontWeight="400" fontSize="xl">{moment(startDate).format("DD-MMM-YY")} - {moment(endDate).format("DD-MMM-YY")}</Text>:null}
                  <Button
                    disabled={granularity === "yearly"}
                    onClick={() => {
                      handleChangeDatesByArrow("right");
                    }}
                  >
                    <Text fontWeight="600">&gt;</Text>
                  </Button>
                </>
              ) : null}

              <Text style={{ marginTop: "auto", marginBottom: "auto" }}>
                Show Data By:
              </Text>
              <Button
                style={{
                  background: granularity == "hourly" ? "#7CB5EC" : "unset",
                }}
                onClick={() => {
                  resetDatesBasedOnGranularity("hourly");
                }}
              >
                hour
              </Button>
              <Button
                style={{
                  background: granularity == "daily" ? "#7CB5EC" : "unset",
                }}
                onClick={() => {
                  resetDatesBasedOnGranularity("daily");
                }}
              >
                day
              </Button>
              <Button
                style={{
                  background: granularity == "monthly" ? "#7CB5EC" : "unset",
                }}
                onClick={() => {
                  resetDatesBasedOnGranularity("monthly");
                }}
              >
                Month
              </Button>
              <Button
                style={{
                  background: granularity == "yearly" ? "#7CB5EC" : "unset",
                }}
                onClick={() => {
                  resetDatesBasedOnGranularity("yearly");
                }}
              >
                Year
              </Button>
            </ButtonGroup>
          </HStack>
        </VStack>
      </Flex>
    </>
  );
}
export default FilteringComponent;
