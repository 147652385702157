import { useRef, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  FormControl,
  FormHelperText,
  InputRightElement,
  useToast,
  Image,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import authUserApi from "../../api/authUserApi";
import useAuth from "../../shared/hooks/useAuth";
import companyLogo from "../../assets/logos/logofinaltopdown.png";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function UserLoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);

  const { setAuth }: any = useAuth();
  const errRef = useRef<HTMLParagraphElement>(null);
  const [email, setUser] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.path || "/";

  const toast = useToast();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if(!email || !password){
      return;
    }
    try {
      setLoading(true);
      const response = await authUserApi.post<any>(
        "authenticate",
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      const accessToken = response?.data?.access_token;
      const roles = response?.data?.user_id;
      const userName = response?.data.user_details.name;
      const userId = response?.data.user_details.id;
      const userDetails = response?.data.user_details;
      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("userName", userName);
      sessionStorage.setItem("roles", roles);
      sessionStorage.setItem("userDetails", userDetails);
      setAuth({ email, userId, roles, accessToken, userName, userDetails });
      setUser("");
      setPwd("");
      setLoading(false);
      navigate("/dashboard", { replace: true });
    } catch (err: any) {
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
        showMessage("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else {
        setErrMsg("Login Failed");
        showMessage("Login Failed");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }finally {
      setLoading(false);
    }
  };

  function showMessage(errMsg: string) {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: "error",
    });
  }

  function handleMenuItemNavigate(to: string) {
    navigate("/" + to);
  }

  async function handleForgetPasswordClick(){
    try{
      if(email==""){
        setErrMsg("Please enter a valid email to reset the password.");
        return;
      }
      const response = await authUserApi.post<any>(
        "forgot-password",
        JSON.stringify({ email }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if(response?.data?.user_id){
        navigate("/reset-user-password", { replace: true });
      }
    }catch(err: any){
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Email is invalid.");
        showMessage(err.response?.data.errors);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else {
        setErrMsg("Email is invalid.");
        showMessage(err.response?.data.errors);
      }
      errRef.current !== null ? errRef.current.focus() : ""; 
    }
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="top"
        alignItems="center"
      >
        <Image
          src={companyLogo}
          alt="ensight logo"
          boxSize="58%"
          objectFit="contain"
        />
        {/* <Heading
          color="purple"
          fontFamily="monospace"
          fontWeight="bold"
          mb="4"
        ></Heading> */}
        <p
          ref={errRef}
          style={{ color: errMsg ? "red" : "inherit" }}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box minW={{ base: "100%", md: "468px" }}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="email"
                    placeholder="email address"
                    onChange={(e) => setUser(e.target.value)}
                    value={email}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {/* No use for this. (ESP)Customers so far are only created manully by the team */}
                {/* <FormHelperText textAlign="right">
                  <Link onClick={() => handleForgetPasswordClick()}>forgot password?</Link>
                </FormHelperText>
                <FormHelperText textAlign="right">
                  <Link onClick={() => handleMenuItemNavigate("signup-user")}>create account</Link>
                </FormHelperText> */}
              </FormControl>
              <Button
                isLoading={loading}
                loadingText="Submitting"
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="teal"
                width="full"
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}

export default UserLoginPage;
