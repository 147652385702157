// ../../../react-shim.js
import React from "react";

// src/index.ts
export * from "@chakra-ui/accordion";
export * from "@chakra-ui/alert";
export * from "@chakra-ui/avatar";
export * from "@chakra-ui/breadcrumb";
export * from "@chakra-ui/button";
export * from "@chakra-ui/card";
export * from "@chakra-ui/checkbox";
export * from "@chakra-ui/close-button";
export * from "@chakra-ui/control-box";
export * from "@chakra-ui/counter";
export * from "@chakra-ui/css-reset";
export * from "@chakra-ui/editable";
export * from "@chakra-ui/form-control";
export * from "@chakra-ui/hooks";
export * from "@chakra-ui/icon";
export * from "@chakra-ui/image";
export * from "@chakra-ui/input";
export * from "@chakra-ui/layout";
export * from "@chakra-ui/media-query";
export * from "@chakra-ui/menu";
export * from "@chakra-ui/modal";
export * from "@chakra-ui/number-input";
export * from "@chakra-ui/pin-input";
export * from "@chakra-ui/popover";
export * from "@chakra-ui/popper";
export * from "@chakra-ui/portal";
export * from "@chakra-ui/progress";
export * from "@chakra-ui/radio";
export * from "@chakra-ui/react-env";
export * from "@chakra-ui/select";
export * from "@chakra-ui/skeleton";
export * from "@chakra-ui/slider";
export * from "@chakra-ui/spinner";
export * from "@chakra-ui/stat";
export * from "@chakra-ui/switch";
export * from "@chakra-ui/system";
export * from "@chakra-ui/table";
export * from "@chakra-ui/tabs";
export * from "@chakra-ui/tag";
export * from "@chakra-ui/textarea";
export * from "@chakra-ui/theme";
export * from "@chakra-ui/toast";
export * from "@chakra-ui/tooltip";
export * from "@chakra-ui/transition";
export * from "@chakra-ui/visually-hidden";
export * from "@chakra-ui/theme-utils";

// src/chakra-provider.tsx
import {
  ChakraProvider as BaseChakraProvider
} from "@chakra-ui/provider";
import { theme as defaultTheme, baseTheme } from "@chakra-ui/theme";
import { ToastProvider } from "@chakra-ui/toast";
var createChakraProvider = (providerTheme) => {
  return function ChakraProvider2({
    children,
    theme = providerTheme,
    toastOptions,
    ...restProps
  }) {
    return /* @__PURE__ */ React.createElement(BaseChakraProvider, {
      theme,
      ...restProps
    }, children, /* @__PURE__ */ React.createElement(ToastProvider, {
      ...toastOptions
    }));
  };
};
var ChakraProvider = createChakraProvider(defaultTheme);
var ChakraBaseProvider = createChakraProvider(baseTheme);
export {
  ChakraBaseProvider,
  ChakraProvider
};
