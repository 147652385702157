import { AxiosRequestConfig } from "axios";
import { useState, useEffect, useContext } from "react";
import UserDeviceDataApi from "../../api/userDeviceDataApi";
import AuthContext from "../../components/authentication/auth-context/AuthContext";

export default function useGetHook({ url, has_device_id }: any): any {
  const [data, setData] = useState({
    current_period: [],
    previous_period_total: 0,
    current_period_total: 0,
    next_period_total: 0,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = UserDeviceDataApi();
  const { auth, setAuth }: any = useContext(AuthContext);
  
  function handleSignOut() {
    sessionStorage.setItem("access_token", "");
    sessionStorage.setItem("email", "");
    sessionStorage.setItem("userId", "");
    sessionStorage.setItem("userName", "");
    sessionStorage.setItem("roles", "");
    sessionStorage.setItem("userDetails", "");
    setAuth({
      email: "",
      userId: "",
      roles: "",
      accessToken: "",
      userName: "",
      user_details: "",
    });
  }

  useEffect(() => {
    function init() {
      try {
        setLoading(true);
        if (has_device_id) {
          api.get("/device-30days-consumption",{params:{ext_device_id: url.replace(":", "")} as AxiosRequestConfig})
            .then((response) => {
              setData(response.data);
              setLoading(false);
            })
            .catch((err) => {
              setError(err);
              if(err.code=='ERR_BAD_REQUEST'){
                if(err.response.data.message=="Unauthorized"){
                  //logout when jwt expires
                  handleSignOut();
                }
              }
            });
        } else {
          api.get("/esp-30days-consumption")
            .then((response) => {
              setData(response.data);
              setLoading(false);
            })
            .catch((err) => {
              setError(err);
              if(err.code=='ERR_BAD_REQUEST'){
                if(err.response.data.message=="Unauthorized"){
                  handleSignOut();
                }
              }
            });
        }
      } catch (e: any) {
        setError(e);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    init();
  }, [url,has_device_id]);

  return { data, error, loading };
}
