import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SidebarWithHeader from "../../shared/sidebar/sidebar.component";

function Dashboard() {
  let navigate = useNavigate();
  useEffect(()=>{
    navigate('/dashboard')
  },[])
  return (
    <>
      <SidebarWithHeader children={<Outlet />} />
    </>
  );
}
export default Dashboard;
