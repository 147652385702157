import { AxiosRequestConfig } from "axios";
import { useState } from "react";
import UserDataApi from "../../api/userDataApi";

export default function useSearch(): any {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = UserDataApi();

  const request = async (qparams: any) => {
    setLoading(true);
    try {
      let res = { data: {} };

      if (/^\d{8}$/.test(qparams)) {
        res = await api.get("/supplies", {
          params: { supply_nr: qparams } as AxiosRequestConfig,
        });
        //}else if(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(props)){
      } else if (/^shellyem3-\w{12}$/.test(qparams)) {
        res = await api.get("/supplies", {
          params: { ext_device_id: qparams } as AxiosRequestConfig,
        });
      } else if (/^\d{9}$/.test(qparams)) {
        res = await api.get("/supplies", {
          params: { tin: qparams } as AxiosRequestConfig,
        });
      }
      setData(res.data);
    } catch (e: any) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  return { data, error, loading, request };
}
