// ../../../react-shim.js
import React from "react";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// src/card.tsx
import {
  forwardRef,
  chakra,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// src/card-context.tsx
import { createContext } from "@chakra-ui/react-context";
var [CardStylesProvider, useCardStyles] = createContext({
  name: "CardStylesContext",
  hookName: "useCardStyles",
  providerName: "<Card />"
});

// src/card.tsx
var Card = forwardRef(function Card2(props, ref) {
  const {
    className,
    children,
    direction = "column",
    justify,
    align,
    ...rest
  } = omitThemingProps(props);
  const styles = useMultiStyleConfig("Card", props);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    className: cx("chakra-card", className),
    __css: {
      display: "flex",
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
      position: "relative",
      minWidth: 0,
      wordWrap: "break-word",
      ...styles.container
    },
    ...rest
  }, /* @__PURE__ */ React.createElement(CardStylesProvider, {
    value: styles
  }, children));
});
var CardHeader = forwardRef(function CardHeader2(props, ref) {
  const { className, ...rest } = props;
  const styles = useCardStyles();
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    className: cx("chakra-card__header", className),
    __css: styles.header,
    ...rest
  });
});
var CardBody = forwardRef(function CardBody2(props, ref) {
  const { className, ...rest } = props;
  const styles = useCardStyles();
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    className: cx("chakra-card__body", className),
    __css: styles.body,
    ...rest
  });
});
var CardFooter = forwardRef(function CardFooter2(props, ref) {
  const { className, justify, ...rest } = props;
  const styles = useCardStyles();
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    className: cx("chakra-card__footer", className),
    __css: {
      ...styles.footer,
      display: "flex",
      justifyContent: justify
    },
    ...rest
  });
});
export {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  useCardStyles
};
