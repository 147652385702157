import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
  Image,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import authUserApi from "../../api/authUserApi";
import useAuth from "../../shared/hooks/useAuth";
import companyLogo from '../../assets/logos/logo_final.png';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const { setAuth }: any = useAuth();
  const errRef = useRef<HTMLParagraphElement>(null);
  const [resetToken, setResetToken] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  let navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  useEffect(()=>{
    showMessage(`We have send you an email with Instructions. Please check your email: ${sessionStorage.getItem('email')}`,"info");
  },[])
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await authUserApi.post<any>(
        "reset-password",
        JSON.stringify({ token: resetToken, password: password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      navigate("/login", { replace: true });
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        if(resetToken==""){
          setErrMsg("Empty Verification Code or email.");
        }else{
          setErrMsg("Verification Code is Invalid or Expired.");
        }
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else if(err.response?.status === 404) {
        if(resetToken==""){
            setErrMsg("Empty reset password token or password.");
        }else{
            showMessage(err.response?.data.errors);
        }
      }else {
        setErrMsg("Code Verification Failed");
        showMessage("Code Verification Failed");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  };

  function showMessage(errMsg: string, status: "info" | "warning" | "success" | "error" | "loading" | undefined = "error") {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={companyLogo}
          alt="ensight logo"
          boxSize="55%"
          objectFit="cover"
        />
        <Heading
          color="purple"
          fontFamily="monospace"
          fontWeight="bold"
          mb="4"
        ></Heading>
        <p
          ref={errRef}
          style={{ color: errMsg ? "red" : "inherit" }}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder="reset password token from email"
                    onChange={(e) => setResetToken(e.target.value)}
                    value={resetToken}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
              <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="NEW Password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="teal"
                width="full"
              >
                Submit Change
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}

export default ResetPassword;
