// src/extend-theme.ts
import {
  theme,
  baseTheme,
  isChakraTheme
} from "@chakra-ui/theme";
import mergeWith from "lodash.mergewith";
function isFunction(value) {
  return typeof value === "function";
}
function pipe(...fns) {
  return (v) => fns.reduce((a, b) => b(a), v);
}
var createExtendTheme = (theme2) => {
  return function extendTheme2(...extensions) {
    let overrides = [...extensions];
    let activeTheme = extensions[extensions.length - 1];
    if (isChakraTheme(activeTheme) && overrides.length > 1) {
      overrides = overrides.slice(0, overrides.length - 1);
    } else {
      activeTheme = theme2;
    }
    return pipe(...overrides.map((extension) => (prevTheme) => isFunction(extension) ? extension(prevTheme) : mergeThemeOverride(prevTheme, extension)))(activeTheme);
  };
};
var extendTheme = createExtendTheme(theme);
var extendBaseTheme = createExtendTheme(baseTheme);
function mergeThemeOverride(...overrides) {
  return mergeWith({}, ...overrides, mergeThemeCustomizer);
}
function mergeThemeCustomizer(source, override, key, object) {
  if ((isFunction(source) || isFunction(override)) && Object.prototype.hasOwnProperty.call(object, key)) {
    return (...args) => {
      const sourceValue = isFunction(source) ? source(...args) : source;
      const overrideValue = isFunction(override) ? override(...args) : override;
      return mergeWith({}, sourceValue, overrideValue, mergeThemeCustomizer);
    };
  }
  return void 0;
}

// ../shared-utils/src/index.ts
function isObject(value) {
  const type = typeof value;
  return value != null && (type === "object" || type === "function") && !Array.isArray(value);
}

// src/theme-extensions/with-default-color-scheme.ts
function withDefaultColorScheme({
  colorScheme,
  components
}) {
  return (theme2) => {
    let names = Object.keys(theme2.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme2, {
      components: Object.fromEntries(names.map((componentName) => {
        const withColorScheme = {
          defaultProps: {
            colorScheme
          }
        };
        return [componentName, withColorScheme];
      }))
    });
  };
}

// src/theme-extensions/with-default-size.ts
function withDefaultSize({
  size,
  components
}) {
  return (theme2) => {
    let names = Object.keys(theme2.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme2, {
      components: Object.fromEntries(names.map((componentName) => {
        const withSize = {
          defaultProps: {
            size
          }
        };
        return [componentName, withSize];
      }))
    });
  };
}

// src/theme-extensions/with-default-variant.ts
function withDefaultVariant({
  variant,
  components
}) {
  return (theme2) => {
    let names = Object.keys(theme2.components || {});
    if (Array.isArray(components)) {
      names = components;
    } else if (isObject(components)) {
      names = Object.keys(components);
    }
    return mergeThemeOverride(theme2, {
      components: Object.fromEntries(names.map((componentName) => {
        const withVariant = {
          defaultProps: {
            variant
          }
        };
        return [componentName, withVariant];
      }))
    });
  };
}

// src/theme-extensions/with-default-props.ts
function pipe2(...fns) {
  return (v) => fns.reduce((a, b) => b(a), v);
}
function withDefaultProps({
  defaultProps: { colorScheme, variant, size },
  components
}) {
  const identity = (t) => t;
  const fns = [
    colorScheme ? withDefaultColorScheme({ colorScheme, components }) : identity,
    size ? withDefaultSize({ size, components }) : identity,
    variant ? withDefaultVariant({ variant, components }) : identity
  ];
  return (theme2) => mergeThemeOverride(pipe2(...fns)(theme2));
}
export {
  extendBaseTheme,
  extendTheme,
  mergeThemeOverride,
  withDefaultColorScheme,
  withDefaultProps,
  withDefaultSize,
  withDefaultVariant
};
