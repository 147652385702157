import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import UserDeviceDataApi from "../../api/userDeviceDataApi";
import AuthContext from "../../components/authentication/auth-context/AuthContext";

export default function useFilterHook() {
  const [data, setData] = useState({
    period: [],
    datapoints: [],
    total: 0,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const api = UserDeviceDataApi();
  const { id } = useParams();
  const { auth, setAuth }: any = useContext(AuthContext);
  let url:string = "/device-consumption";
  function handleSignOut() {
    sessionStorage.setItem("access_token", "");
    sessionStorage.setItem("email", "");
    sessionStorage.setItem("userId", "");
    sessionStorage.setItem("userName", "");
    sessionStorage.setItem("roles", "");
    sessionStorage.setItem("userDetails", "");
    setAuth({
      email: "",
      userId: "",
      roles: "",
      accessToken: "",
      userName: "",
      user_details: "",
    });
  }

  async function makeRequest(granularity: any, startDate: any, endDate: any) {
    setLoading(true);
    try {
      let body="";
      if (!id) {
        url = "/esp-consumption";
        body = JSON.stringify({
          start: startDate,
          end: endDate,
          granularity: granularity,
        });
        if (!startDate && !endDate) {
          body = JSON.stringify({
            granularity: granularity,
          });
        } else if (!startDate && endDate) {
          body = JSON.stringify({
            end: endDate,
            granularity: granularity,
          });
        } else if (startDate && !endDate) {
          body = JSON.stringify({
            start: startDate,
            granularity: granularity,
          });
        }
      } else {
        url = "/device-consumption";
        body = JSON.stringify({
          ext_device_id: id?.replace(":", ""),
          start: startDate,
          end: endDate,
          granularity: granularity,
        });
        if (!startDate && !endDate) {
          body = JSON.stringify({
            ext_device_id: id?.replace(":", ""),
            granularity: granularity,
          });
        } else if (!startDate && endDate) {
          body = JSON.stringify({
            ext_device_id: id?.replace(":", ""),
            end: endDate,
            granularity: granularity,
          });
        } else if (startDate && !endDate) {
          body = JSON.stringify({
            ext_device_id: id?.replace(":", ""),
            start: startDate,
            granularity: granularity,
          });
        }
      }

      await api
        .post(url, body)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
          if (err.code == "ERR_BAD_REQUEST") {
            if (err.response.data.message == "Unauthorized") {
              handleSignOut();
            }
          }
        });
    } catch (e: any) {
      setError(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  return { makeRequest, data, error, loading };
}
