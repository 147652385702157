import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  useToast,
  Image,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import authUserApi from "../../api/authUserApi";
import useAuth from "../../shared/hooks/useAuth";
import companyLogo from '../../assets/logos/logo_final.png';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

function VerifyAccount() {

  const { setAuth }: any = useAuth();
  const errRef = useRef<HTMLParagraphElement>(null);
  const [email, setUser] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  let navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.path || "/";
  const toast = useToast();
  useEffect(()=>{
    setUser(sessionStorage.getItem('email')??"");
    showMessage(`We have send you an email with a verification code. Please check your email: ${email}`,"info");
  },[])
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await authUserApi.post<any>(
        "verify",
        JSON.stringify({ email, code: verificationCode }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const accessToken = response?.data?.access_token;
      
      const userName = response?.data.user_details.name;
      const userId = response?.data.user_details.id;
      const roles = userId;
      const userDetails = response?.data.user_details;
      sessionStorage.setItem("access_token", accessToken);
      email??sessionStorage.setItem("email", email);
      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("userName", userName);
      sessionStorage.setItem("roles", roles);
      sessionStorage.setItem("userDetails", userDetails);
      setAuth({ email, userId, roles, accessToken, userName, userDetails });
      setUser("");
      setVerificationCode("");
      navigate("/dashboard", { replace: true });
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        showMessage("No Server Response");
      } else if (err.response?.status === 400) {
        if(verificationCode==""){
          setErrMsg("Empty Verification Code or email.");
        }else{
          setErrMsg("Verification Code is Invalid or Expired.");
        }
        showMessage(err.response?.data.errors);
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
        showMessage("Unauthorized");
      } else {
        setErrMsg("Code Verification Failed");
        showMessage("Code Verification Failed");
      }
      errRef.current !== null ? errRef.current.focus() : "";
    }
  };

  function showMessage(errMsg: string, status: "info" | "warning" | "success" | "error" | "loading" | undefined = "error") {
    toast({
      title: errMsg,
      position: "top-right",
      isClosable: true,
      status: status,
    });
  }

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={companyLogo}
          alt="ensight logo"
          boxSize="55%"
          objectFit="cover"
        />
        <Heading
          color="purple"
          fontFamily="monospace"
          fontWeight="bold"
          mb="4"
        ></Heading>
        <p
          ref={errRef}
          style={{ color: errMsg ? "red" : "inherit" }}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="email"
                    placeholder="email address"
                    onChange={(e) => setUser(e.target.value)}
                    value={email}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder="Verification Code"
                    onChange={(e) => setVerificationCode(e.target.value)}
                    value={verificationCode}
                  />
                </InputGroup>
              </FormControl>
              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="teal"
                width="full"
              >
                Verify Account
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}

export default VerifyAccount;
