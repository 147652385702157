import HightChartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Box, Card } from "@chakra-ui/react";

export default function AreaChart(props: any) {
  let liveData = [];
  let chartTitle = "Avergae of ESP";
  let isShowingHourly = false;
  if (props.data?.current_period) {
    liveData = props.data.current_period.map((x: string) => {
      return [new Date(x[0]).getTime(), x[1]];
    });
  } else {
    chartTitle = props.id ?? "Avergae of ESP";
    if (
      props.data?.datapoints.length > 0
        ? props.data?.datapoints[0][0].indexOf("T") > 0
        : false
    ) {
      isShowingHourly = true;
    } else {
      isShowingHourly = false;
    }
    liveData = props.data.datapoints.map((x: string) => {
      //TODO: hourly data without restrictions may fetch more data than the line chart can show
      if (x[0].indexOf("T00") > 0) {
        return [new Date(x[0].replace("T00", ":00")).getTime(), x[1]];
      } else if (x[0].indexOf("T") > 0) {
        return [new Date(x[0].concat(":00")).getTime(), x[1]];
      } else {
        return [new Date(x[0]).getTime(), x[1]];
      }
    });
    console.log(liveData);
  }
  const options: Highcharts.Options = {
    title: {
      text: `Live Monitoring of ${chartTitle}`,
    },
    xAxis: {
      title: {
        text: "Date",
      },
      type: "datetime",
      labels: {
        format: isShowingHourly ? "{value:%d/%m %H:%M}" : "{value:%d/%m/%y}",
      },
    },
    yAxis: {
      title: {
        text: "KWh",
      },
    },
    series: [
      {
        type: "line",
        name: "KWh/Date",
        data: liveData,
      },
    ],
  };

  return (
    <Box
      p={5}
      borderRadius="6px"
      shadow="md"
      borderWidth="1px"
      backgroundColor="white"
    >
      <HightChartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}
